import React, { lazy } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import ContextLayout from "../components/template/ContextLayout";

/** Template */
const HomeLayout = lazy(() => import("../components/template/HomeLayout"));

/** Board home page */
const Home = lazy(() => import("../pages/home/Home"));

/*****************************************************************************
 * REVISION 1.0
 * Fecha: 05/03/2023
 *
 * @description Componente padre para manejo de rutas en la app
 * @author mixtico
 * @version 1.0
 *
 ****************************************************************************/
export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ContextLayout />}>
      <Route element={<HomeLayout />}>
        <Route exact path="/" element={<Home />} />
      </Route>
    </Route>
  )
);
