import React, { createContext, useMemo, useState } from "react";
import { DATA } from "../data/AppData";
//define context
const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  const [productData, setProductData] = useState(DATA.product);
  const [eventData, setEventData] = useState(DATA.event);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: null,
    modalObject: null,
  });

  const resetErrorMsg = () => {
    setErrorMsg(null);
  };

  const resetSuccessMsg = () => {
    setSuccessMsg(null);
  };

  const resetAllMsg = () => {
    setErrorMsg(null);
    setSuccessMsg(null);
  };

  const values = useMemo(
    () => ({
      productData,
      eventData,
      isLoading,
      errorMsg,
      successMsg,
      modalData,
      setProductData,
      setEventData,
      setLoading,
      resetErrorMsg,
      setErrorMsg,
      resetSuccessMsg,
      setSuccessMsg,
      resetAllMsg,
      setModalData,
    }),
    // eslint-disable-next-line
    [productData, eventData, isLoading, errorMsg, successMsg, modalData]
  );

  return <Provider value={values}>{children}</Provider>;
};

export { AppContext, AppProvider };
