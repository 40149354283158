import { v4 as uuidv4 } from "uuid";
import cerezaFire from "../resources/image/product/cereza-fire/cereza-fire.png";
import pinaColada from "../resources/image/product/pina-colada/pina-colada.png";
import mojitoMenta from "../resources/image/product/mojito-menta/mojito-menta.png";
import cocotico from "../resources/image/product/cocotico/cocotico.png";
import blueCuracao from "../resources/image/product/blue-curacao/blue-curacao.png";
import manzanaKiwi from "../resources/image/product/manzana-kiwi/manzana-kiwi.png";
import daikiri from "../resources/image/product/daikiri/daikiri.png";
import frutas from "../resources/image/product/frutas/frutas.png";
import margarita from "../resources/image/product/margarita/margarita.png";
import menta from "../resources/image/product/menta/menta.png";
import granadina from "../resources/image/product/granadina/granadina.png";
//event images
import portadaBayer from "../resources/image/events/evento-bayer/bayer01.JPG";
import eventBayer02 from "../resources/image/events/evento-bayer/bayer02.JPG";
import eventBayer03 from "../resources/image/events/evento-bayer/bayer03.JPG";
import eventBayer04 from "../resources/image/events/evento-bayer/bayer04.JPG";
import eventBayer05 from "../resources/image/events/evento-bayer/bayer05.JPG";
import portadaeventHome from "../resources/image/events/eventhome/eventhome01.JPG";
import eventHome02 from "../resources/image/events/eventhome/eventhome02.JPG";
import eventHome03 from "../resources/image/events/eventhome/eventhome03.JPG";
import portada3M from "../resources/image/events/evento-3M/3M01.jpg";
import event3M02 from "../resources/image/events/evento-3M/3M02.jpg";
import event3M03 from "../resources/image/events/evento-3M/3M03.jpg";
import event3M04 from "../resources/image/events/evento-3M/3M04.jpg";
import event3M05 from "../resources/image/events/evento-3M/3M05.jpg";
import portadavolcan from "../resources/image/events/evento-volcan/volcan01.jpg";
import eventVolcan02 from "../resources/image/events/evento-volcan/volcan02.jpg";
import eventVolcan03 from "../resources/image/events/evento-volcan/volcan03.jpg";
import eventVolcan04 from "../resources/image/events/evento-volcan/volcan04.jpg";
import eventVolcan05 from "../resources/image/events/evento-volcan/volcan05.jpg";
import portadaBoda from "../resources/image/events/eventos-bodas/boda01.jpg";
import eventBoda02 from "../resources/image/events/eventos-bodas/boda02.jpg";
import eventBoda03 from "../resources/image/events/eventos-bodas/boda03.jpg";
import eventBoda04 from "../resources/image/events/eventos-bodas/boda04.jpg";
import eventBoda05 from "../resources/image/events/eventos-bodas/boda05.jpg";
import eventBoda06 from "../resources/image/events/eventos-bodas/boda06.jpg";
import eventBoda07 from "../resources/image/events/eventos-bodas/boda07.jpg";
import eventBoda08 from "../resources/image/events/eventos-bodas/boda08.jpg";
import eventBoda09 from "../resources/image/events/eventos-bodas/boda09.jpg";
import portadaFeria from "../resources/image/events/feria-palmares/feria01.JPG";
import eventFeria02 from "../resources/image/events/feria-palmares/feria02.JPG";
import eventFeria03 from "../resources/image/events/feria-palmares/feria03.JPG";
import eventFeria04 from "../resources/image/events/feria-palmares/feria04.JPG";
import eventFeria05 from "../resources/image/events/feria-palmares/feria05.JPG";
/*****************************************************************************
 * REVISION 1.0
 * Fecha: 05/03/2023
 *
 * @description JS para manejo de datos de la aplicacion
 * @author mixtico
 * @version 1.0
 *
 ****************************************************************************/
export const DATA = {
  product: [
    {
      id: uuidv4(),
      name: "Menta",
      img: menta,
      color: "#35c42c",
      mulPreparation: false,
      preparation: [
        "Agrega hielo entre 8 y 10 cubos según preferencia.",
        "Agrega 2 onzas de Menta Mixtico.",
        "Agrega 1 onza del licor de su preferencia.",
        "En coctelera agite por 30 segundos y sirva.",
        "Para “frozen” licúe a velocidad media por 1 minuto hasta derretir el hielo.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "Las combinaciones más interesantes para tus cócteles, las podés obtener con nuestra mezcla de Menta Mixtico. ¡Nunca antes tus ideas podrían ser tan refrescantes!",
    },
    {
      id: uuidv4(),
      name: "Granadina",
      img: granadina,
      color: "#8b0106",
      mulPreparation: false,
      preparation: [
        "Agrega hielo entre 8 y 10 cubos según preferencia.",
        "Agrega 2 onzas de Granadina Mixtico.",
        "Agrega 1 onza del licor de su preferencia.",
        "En coctelera agite por 30 segundos y sirva.",
        "Para “frozen” licúe a velocidad media por 1 minuto hasta derretir el hielo.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "El mejor sabor, color rojizo y textura para tus cócteles con nuestra mezcla Granadina que solo Mixtico te ofrece! Con nuestra Granadina podés preparar deliciosos cócteles como Tequila Sunrise, Orange Crush y muchos más..",
    },
    {
      id: uuidv4(),
      name: "Cereza Fire",
      img: cerezaFire,
      color: "#8b0106",
      mulPreparation: false,
      preparation: [
        "Agrega 1,5 onzas de vodka o whisky.",
        "Agrega 1,5 onzas de Mixtico Cereza Fire.",
        "Agrega 8 -10 cubitos de hielo.",
        "Mezcla por 1 minutos, sirve y enchílate!!.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "Cereza Fire es una mezcla de cereza con canela, la cual produce un picante cuando la bebes. Es deliciosa. Se puede combinar con bebida energética para un sabor increíble!.",
    },
    {
      id: uuidv4(),
      name: "Piña Colada con Coco",
      img: pinaColada,
      color: "#e7c500",
      mulPreparation: false,
      preparation: [
        "En licuadora-Frozen:",
        "Agrega 1,5 onzas de ron claro/oscuro.",
        "Agrega 2,5 onzas de  Mixtico Piña  Colada.",
        "Agrega 8-10 cubitos de hielo  hasta derretir el hielo.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "Nuestra piña colada contiene coco, por lo que sólo necesitas agregar hielo y ron.Puedes mezclar con Blue Curacao para dar un  sabor y color increíble. **No contiene leche",
    },
    {
      id: uuidv4(),
      name: "Mojito Menta",
      img: mojitoMenta,
      color: "#35c42c",
      mulPreparation: false,
      preparation: [
        "Agrega 1,5 onzas de ron blanco /oscuro.",
        "Agrega 1,5 onzas de Mixtico Mojito Menta.",
        "Agrega 8 -10 cubitos de hielo.",
        "Mezcla con la coctelera por 1 minuto , sirve y disfruta.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "Disfrútalo también virgen (sin licor). Bebida refrescante! Puede agregar una hoja de yerbabuena como adorno.",
    },
    {
      id: uuidv4(),
      name: "Cocotico",
      img: cocotico,
      color: "#0763BD",
      mulPreparation: false,
      preparation: [
        "Puede utilizarlo para:",
        "Piña colada natural.",
        "Postres y repostería en general.",
        "Miguelitos.",
      ],
      presentation: ["Galón"],
      detail:
        "Nuestro cocotico es una mezcla  a base de coco natural, con fibra, el cual permite utilizarlo para repostería (flanes, tres leches) y cualquier postre donde utilices coco. Para piñas coladas puede utilizar cocotico.",
    },
    {
      id: uuidv4(),
      name: "Blue Curacao",
      img: blueCuracao,
      color: "#0763BD",
      mulPreparation: false,
      preparation: [
        "Agrega 1,5 onzas de vodka o whisky.",
        "Agrega  1,5 onzas de Mixtico Blue  Curacao.",
        "Agrega 8 -10 cubitos de hielo.",
        "Mezcla por 1 minutos, sirve y Disfruta!!.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "El  Blue Curacao lo puedes mezclar con el licor que desees, es rico sabor de vainilla y naranja.",
    },
    {
      id: uuidv4(),
      name: "Manzana Kiwi",
      img: manzanaKiwi,
      color: "#35c42c",
      mulPreparation: false,
      preparation: [
        "Agrega 1,5 onzas de guaro ó ginebra.",
        "Agrega  1,5 onzas de Mixtico Manzana Kiwi.",
        "Agrega 8 -10 cubitos de hielo.",
        "Mezcla por 1 minutos, sirve y Disfruta!!.",
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail: "Puedes sustituir el guaro por ginebra  o bien tomarlo virgen.",
    },
    {
      id: uuidv4(),
      name: "Daiquirí de Fresa",
      img: daikiri,
      color: "#8b0106",
      mulPreparation: true,
      preparation: [
        {
          name: "Opción 1",
          instructions: [
            "En licuadora-Frozen:",
            "Agrega 1,5 onzas de ron claro/oscuro.",
            "Agrega 2,5 onzas de  Mixtico Daiquiri  de fresa.",
            "Agrega 8-10 cubitos de  hielo  hasta derretir el hielo.",
          ],
        },
        {
          name: "Opción 2",
          instructions: [
            "En Coctelera:",
            "Agrega 1,5 onzas de ron blanco /oscuro",
            "Agrega 2,5 onzas de  Mixtico Daiquiri  de fresa.",
            "Agrega 8-10 cubitos de  hielo  hasta derretir el hielo.",
            "Mezcla con la coctelera por 1 minuto, sirve y disfruta!.",
          ],
        },
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail: "Mixtico Daikiri de Fresa contiene trocitos de fresa  natural.",
    },
    {
      id: uuidv4(),
      name: "Fusión de Frutas",
      img: frutas,
      color: "#e7c500",
      mulPreparation: true,
      preparation: [
        {
          name: "Opción 1",
          instructions: [
            "En licuadora-Frozen:",
            "Agrega 1,5 onzas de  ron o ginebra.",
            "Agrega 2 onzas de  Mixtico Fusion  de frutas.",
            "Agrega 8-10 cubitos de  hielo  hasta derretir el hielo.",
          ],
        },
        {
          name: "Opción 2",
          instructions: [
            "En Coctelera:",
            "Agrega 1,5 onzas de ron o ginebra.",
            "Agrega 2 onzas de  Mixtico Fusion  de frutas.",
            "Agrega 8-10 cubitos de hielo.",
            "Mezcla con la coctelera por 1 minuto, sirve y disfruta!",
          ],
        },
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "El Fusión de frutas viene listo para combinar con  ron o con ginebra, sin  embargo puede tomarse  virgen como refresco  natural. Contiene  trocitos de melocotón  natural  y predomina el sabor a maracuyá.",
    },
    {
      id: uuidv4(),
      name: "Margarita",
      img: margarita,
      color: "#35c42c",
      mulPreparation: true,
      preparation: [
        {
          name: "Opción 1",
          instructions: [
            "En licuadora-Frozen:",
            "Agrega 1,5 onzas de tequila.",
            "Agrega 2,5 onzas de  Mixtico Margarita.",
            "Agrega 8-10 cubitos de  hielo  hasta derretir el hielo.",
          ],
        },
        {
          name: "Opción 2",
          instructions: [
            "En Coctelera:",
            "Agrega 1,5 onzas de tequila.",
            "Agrega 1,5 onzas de  Mixtico Margarita.",
            "Agrega 8-10 cubitos de hielo.",
            "Mezcla con la coctelera por 1 minuto, sirve y disfruta!.",
          ],
        },
      ],
      presentation: ["750 ML", "1 Litro", "Galón"],
      detail:
        "Margarita lista para combinar con Tequila y opcional adiciona Mixtico Blue Curacao para convertir en blue Margarita.",
    },
  ],
  event: [
    {
      id: uuidv4(),
      name: "Bayer",
      date: "23/01/2022",
      coverImg: portadaBayer,
      album: [
        portadaBayer,
        eventBayer02,
        eventBayer03,
        eventBayer04,
        eventBayer05,
      ],
    },
    {
      id: uuidv4(),
      name: "3M",
      coverImg: portada3M,
      album: [portada3M, event3M02, event3M03, event3M04, event3M05],
    },
    {
      id: uuidv4(),
      name: "Activaciones",
      coverImg: portadavolcan,
      album: [
        portadavolcan,
        eventVolcan02,
        eventVolcan03,
        eventVolcan04,
        eventVolcan05,
      ],
    },
    {
      id: uuidv4(),
      name: "Feria Palmares",
      coverImg: portadaFeria,
      album: [
        portadaFeria,
        eventFeria02,
        eventFeria03,
        eventFeria04,
        eventFeria05,
      ],
    },
    {
      id: uuidv4(),
      name: "Boda",
      coverImg: eventBoda07,
      album: [
        portadaBoda,
        eventBoda02,
        eventBoda03,
        eventBoda04,
        eventBoda05,
        eventBoda06,
        eventBoda07,
        eventBoda08,
        eventBoda09,
      ],
    },
    {
      id: uuidv4(),
      name: "Home",
      coverImg: portadaeventHome,
      album: [portadaeventHome, eventHome02, eventHome03],
    },
  ],
};
